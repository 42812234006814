.contactuspage_homepage,
.form {
    height: 100vh;
    width: 100%;
    position: relative;
}

.contactuspage_homepage>img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.top_inner_text {
    position: absolute;
    height: 70vh;
    width: 50%;
    top: 15%;
    left: 5%;
}

.top_inner_text>h1 {
    color: #004aad;
    font-size: 130px !important;
    font-weight: 700;
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
    text-transform: uppercase;
}

@media only screen and (max-width : 600px) {
    .contactuspage_homepage {
        height: 50vh;
        width: 100%;
        position: relative;
    }

    .contactuspage_homepage>img {
        width: 100%;
        height: 50vh;
    }

    .top_inner_text {
        position: absolute;
        height: fit-content;
        width: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .top_inner_text>h1 {
        font-size: 30px !important;
    }
}