.heading,.solar_info {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: #f4f7f9;
}
.heading > h1 {
    font-size: 3rem;
    margin-top: 3rem;
}
.heading > h4 {
    width: 50%;
}
.heading > h4 , .solar_features > h4{
    text-align: center;
    line-height: 1.5;
    color: #757e8c;
}
.iotdashboard_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f7f9;
}

.iotdashboard_image_container {
    height: 700px;
    width: 80%;
    padding: 0 10px;
}
.dashboardinfoContent{
    text-align: justify;
}

.img-iotDashboard {
    /* height: 100%; */
    width: 100%;
    border-radius: 10px;
}
.solar_info{
    width: 80%;
    padding: 10px 10px;
}
.white_bg_container{
    background: white;
}
.key_elements_solar{
    width: 80%;
    padding: 50px;
    background: white;
    border:1px solid #c8e3f2;
    border-radius: 10px;
    margin-bottom: 3rem;
}
.key_elements_solars{
    width: 100%;
    padding: 50px;
    background: white;
    border:1px solid #c8e3f2;
    border-radius: 10px;
    margin-bottom: 3rem;
}
.key_elements_solar > h5 {
    padding: 1rem 1rem;
    background: #a9eedf;
    border-radius: 5px;
    font-size: 1.25rem;
}
.solar_layer_technology > h2{
    padding: 3rem 7rem;
    text-align: center;
}
.key_element_card{
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 1rem;
    border-radius: 10px;
}
.key_element_card > h6{
    padding: 0 2rem;
}
.key_element_card > img{
    padding-right: 20px;
}
.solar_features,.iotdashboardContainer{
    width: 80%;
    padding: 50px;
}
.solar_features > h3 ,.solar_features > h4 {
    text-align: center;
    padding-top: 1rem;
}
.iotdashboard_info > h2 {
    font-weight: 600;
}

/* Media Query */

@media only screen and (max-width: 600px) {
    .heading{
        height: 45vh;
        width: 100%;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .solar_info {
        width: 90% !important;
        display: block;
        gap: 1rem;
        height: fit-content;
        padding: 10px;

    }
    .solar_info > h3{
        font-size: 1.5rem;
    }
    .solar_info > h4 , .solar_info > h5{
        font-size: 1rem;
        padding: 10px;
        font-weight: 400;
    }
    .heading > h1 {
        font-size: 1.5rem;
        margin-top: 5rem;
        padding-left: 10px;
    }
    .heading > h4{
        font-size: 1rem;
        width: 90%;
    }
    .iotdashboard_image_container {
        height: 350px;
        width: 90%;
        padding: 0;
    }
    .key_elements_solar{
        width: 90%;
        padding: 10px 2px;
    }
    .key_elements_solar > h5 {
        padding: 10px;
        background: #a9eedf;
        border-radius: 5px;
        font-size: 1rem;
    }
    .key_element_card{
        display: flex;
        align-items: center;
        padding: 0;
        margin: .5rem;
        border-radius: 10px;
    }
    .key_element_card > img{
        padding-right: 5px;
    }
    .solar_layer_technology > h2{
        padding: 1rem 0.25rem;
        text-align: left;
        font-size: 1rem;
    }
    .key_element_card > h6{
        padding: 0 ;
    }
    .solar_features,.iotdashboardContainer{
        width: 90%;
        padding: 0;
    }
    .solar_features > h3 ,.solar_features > h4 {
        text-align: left;
        padding-top: 0.25rem;
        padding-left: 0.25rem;
        font-size: 1.5rem;
    }
    .iotdashboard_info > h2 {
        font-size: 1.5rem;
    }
}