.product_homepage{
    height: 70vh;
    position: relative;
}
.prod_page_video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.90;
}
.product_top_innertext , .iotx_top_innertext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}
.iotx_top_innertext{
    font-size: 1.5rem;
    width: 60%;
    color: #fff;
}
.nextgen_btns ,.teue,.product_info{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.teue, .product_info{
    flex-direction: column;
}
.product_top_innertext {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 70%;
    color: #fff;
}
.product_image{
    height: 300px;
    width: 400px;
}
.product_thinhskloud_content{
    text-align: justify;
}

.iotx_images_container , .iotx-tf {
    display: flex;
    align-items: center;
    justify-content: center;
}
.iotx-tf {
    gap: 0.5rem;
}
.iotx-tf > article > h1 {
    color: #00b894;
}
.iotx-tf > article > h3 {
    padding-top: 10px;
}
.iotx_images {
    height: 70%;
}
.nextgen_images{
    height: 300px;
    width: 300px;
}
.iotx_realtime_card_container{
    padding: 0 5px 0 30px;
    position: relative;
    padding: 10px 0;
    margin: 20px 0 0 !important;
}
.iotx_realtime_card{
    background: linear-gradient(to right , #5aedd0 , #a3d1f0);
    padding: 50px 10px 10px;
    border-radius: 5px;
    min-height: 200px;
    width: 90%;
}
.productpagecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f7f9;
    position: relative;
}
.iotx_realtime_card_innerhead{
    background: #c7eee6;
    position: absolute;
    left: -1%;
    top: 10%;
    padding: 5px;
    margin: 0;
    width: 50%;
    z-index: 1;
}
.product_deails {
    width: 85% !important;
    padding: 0px 100px ;
}
.thingskloud_info{
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.thingkloudProduct_innerContainer{
    height: 500px;
}
.thingskloud_info > h6 {
    line-height: 2;
}

.highlight_text {
    color: #00b894;
    padding: 10px;
}
.product_images_container{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width : 1500px) {
    .iotx_top_innertext{
        font-size: 1.25rem;
    }
}
@media screen and (max-width : 600px) {
    .product_homepage{
        height: 50vh;
    }
    .prod_page_video {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
    .thingskloud_info{
        height: fit-content;
        padding: 0 10px;
    }
    .product_info > h5 , .byop_contact_info > h4{
        font-size: 16px;
    }
    .product_top_innertext ,.iotx_top_innertext {
        top: 60%;
        gap: 1rem;
        width: 100%;
    }
    .product_top_innertext > h1 , .product_top_innertext > h3 ,.aysgud{
        font-size:18px !important;
    }
    .highlight_text{
        font-size: 18px !important;
        padding: 10px 0px;
    }
    .thingskloud_info > h6 , .product_thinhskloud_content > h5 {
        line-height: 2;
        font-size: 16px;
        font-weight: 400 !important;
    }
    .product_deails {
        width: 100% !important;
        padding: 0px 10px;
        height: fit-content;
    }
}


/* BYOP CSS */

.byop_top_innertext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 70px !important;
    width: 70%;
    color: #ffffff;
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .byop_top_innertext h1 {
    font-size: 70px !important;
    font-weight: 700;
  }
  .byop_image_container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .byop_images{
    height: 80%;
    width: 80%;
  }
  .byoppagecontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f7f9;
    position: relative;
}
.byop_deails {
    width: 85% !important;
    padding: 30px 100px;
    position: relative;
}
.biop_services_container{
    border: 1px solid #f4f7f9;
    border-radius: 10px;
    height: fit-content;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;
    text-align: justify;
}
.byop_technology{
    height: 100vh;
}
.byop_image{
    height: 300px;
    /* width: 80%; */
}

.byop_contact , .awsIoT_contact{
    height: 30vh;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: space-evenly;
}
.awsIoT_contact{
    height: 50vh ;
}

  @media only screen and (max-width: 600px) {
    .homepage{
      height: 50vh;
    }
    .byop_top_innertext {
        width: 100%;
    }

    .byop_top_innertext h1 {
      font-size: 25px !important;
    }
    .byop_deails {
        width: 100%;
        padding: 10px 10px
    }
    .byop_images{
        height: 100%;
        width: 90%;
      }
      .byop_image{
        height: 300px;
        width: 300px;
    }
}

@media only screen and (max-width: 1500px){
    .iotx-tf > article > .typography_heading{
        font-size: 1.5rem;
    }
    .thingskloud_info > h5 {
        line-height: 2;
    }
    .product_deails > h1{
        background-color: orange;
    }
}