.companyPagecontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f7f9;
}

.companyHomepage {
    height: 100vh;
    width: 100vw;
    background: url("../assets/CompanyPage/Digikloud.gif");
    background-position: center;
    background-size: cover;
    position: relative;
}

.companypage_tophead {
    height: 20%;
    width: 60%;
    position: absolute;
    top: 25%;
    left: 10%;
    color: white;

}

.companypage_tophead h1 {
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.companypage_subhead {
    margin-top: 50px;
    color: #ff99bf;
}

.company_page_detail {
    width: 80% !important;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.companyVideo,
.promise_image_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.compny_video_ref {
    height: 90%;
    width: 90%;
    margin-left: 50px;
}

.companyPagePlatformandValues {
    height: 900px;
    background: linear-gradient(#00b894, #6c8aac);
    display: flex;
    justify-content: center;
}

.companyPlatformContainer {
    height: 60%;
    width: 80% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0
}

.companyValuesContainer {
    height: 40%;
    width: 80% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0
}

.projectcard,
.support_info {
    width: 33%;
    text-align: center;
}

.company_platform_container {
    display: flex;
}

.iotPlatformContainer {
    width: 100%;
    display: grid;
}

.company_platform,
.company_vision {
    background: #f4f7f9;
    margin: 0 5px;
    border: 1px solid #6c8aac;
    height: 200px;
    display: flex;
    flex-direction: column !important;
    justify-content: space-evenly;
    border-radius: 10px;
}

.comapny_vision_platform_container {
    width: 80%;
    padding: 50px;
}

.company_platform,
.vision_card>.inner_head {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.company_platform,
.vision_card>.inner_head>h6 {
    padding-left: 10px;
}

.company_platform,
.vision_card>.text {
    padding: 0 15px;
}

.company_vision_container {
    display: flex;
    justify-content: space-between;
}

.vision_bg {
    background: linear-gradient(#00b894, #6c8aac);
}

.vision_card {
    width: 30%;
    background: #f4f7f9;
    border: 1px solid #00b894;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.support {
    height: 250px;
    width: 60%;
    border-radius: 10px;
    background-color: #00b894;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.product_image {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.company_top01 {
    height: 400px;
}

@media screen and (max-width : 600px) {
    .companyHomepage {
        height: 50vh;
        width: 100vw;
    }

    .company_page_detail {
        width: 90% !important;
    }

    .companypage_tophead {
        height: 20%;
        width: 60%;
        position: absolute;
        top: 30%;
        left: 10%;
        color: white;
        text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
    }

    .compny_video_ref {
        height: 100%;
        width: 100%;
        margin-left: 0;
    }

    .companypage_tophead>h1 {
        font-size: 25px;
    }

    .companypage_subhead>h3 {
        font-size: 20px;
    }

    .company_page_detail {
        padding: 20px 0;
    }

    .company_platform {
        padding: 0;
    }

    .company_platform_container {
        display: block;
    }

    .companyPagePlatformandValues {
        height: fit-content;
        background: linear-gradient(#00b894, #6c8aac);
        display: flex;
        justify-content: center;
    }
    .companyPlatformContainer {
        height: fit-content;
        width: 90% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }
    
    .companyValuesContainer {
        height: fit-content;
        width: 90% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }
    .company_vision_container {
        margin: 10px 0;
        display: block;
    }

    .vision_card {
        /* height: 250px; */
        width: 100%;
        background: #f4f7f9;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 10px 0;
    }

    .company_top01 {
        height: fit-content;
    }

    .comapny_vision_platform_container {
        padding: 0;
    }

    .vision_bg {
        background: linear-gradient(#00b894, #6c8aac);
    }

    .iotPlatformContainer {
        height: fit-content;
        width: 100%;
        display: grid;
    }

    .support {
        height: 300px;
        width: 90%;
    }

    .promise_image_container {
        display: block;
        text-align: -webkit-center;
    }

    .support_info {
        width: 100%;
    }
}