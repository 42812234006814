.services_background_image {
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.feautured_info {
    display: flex;
    align-items: center;
    padding: 10px;
}

.feautured {
    height: fit-content;
}

.feautured_data {
    display: flex;
    flex-direction: column !important;
    justify-content: space-evenly;
    padding: 0 50px;
}

.feautured_info p {
    padding-left: 50px;
}

.servicesTopHead,
.iiotTopHead,
.feauturedTopHead,
.thingsboardTopHead {
    width: 50%;
    position: absolute;
    left: 5%;
    top: 40%;
    color: white;
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.thingsboardTopHead {
    width: 70%;
}
.servicesTopHead {
    width: 70%;
    left : 10%;
}

.azure_heading {
    color: #00B894;
}

.azure_list {
    display: flex;
    justify-content: center;
}

.awsServicesContent,
.services_info,
.azureServicesContent,
.iotServicesContent {
    text-align: justify;
}

.awsiotTopHead {
    width: 50%;
    position: absolute;
    left: 0%;
    top: 40%;
    color: black;
    text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.iiotTopHead,
.awsiotTopHead,
.feauturedTopHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 25%;
}

.services_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.services_images {
    height: 300px;
    width: 300px;
}

.awsIot_services_container {
    border: 1px solid #f4f7f9;
    border-radius: 10px;
    height: fit-content;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
}

.azure_services_container,
.thingsboard_details {
    padding: 50px 10px;
    width: 80% !important;
}

.thingsboard_details {
    padding: 10px 10px;
}

.node_red,
.influxDB,
.grafana {
    background: linear-gradient(to left, #00B894, #1CB5B9);
    height: 70vh;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.grafana_header {
    position: absolute;
    top: 50;
    left: 50px;
    width: 50%;
}

.telegraf {
    background: url(../../assets/Services/Feautured/telegraf_bg.webp);
    height: 500px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.telegraf_header {
    position: absolute;
    left: 10%;
    top: 40%;
}

.influxDB_header {
    position: absolute;
    left: 10%;
    display: flex;
}

.influxDB_header-image {
    position: absolute;
    left: 60%;
}
.our_iot_service{
    padding: 50px 100px;
}
.influxDB_details {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 50px 100px;
}

.nodered_header {
    position: absolute;
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nodered_bg_img,
.telegraf_image {
    width: 100%;
}

.nodered_image {
    width: 50%;
}

.nodered_container,
.grafana_container,
.thingsboardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f7f9;
}

.nodered_details,
.grafana_detatils {
    width: 80% !important;
}

.grafana_info {
    padding: 30px 0;
}

.nodered_details_info,
.telegraf_details_info,
.influxDB_info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0;
    height: 500px;
}

.telegraf_details_info {
    height: 600px;
}

.nodered_services_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    padding: 10 50px 50px;
}

@media only screen and (max-width: 600px) {

    .servicesTopHead h1,
    .feauturedTopHead h1,
    .nodered_header h1,
    .telegraf_header h1,
    .influxDB_header h1,
    .grafana_header h1,
    .thingsboardTopHead h1 {
        font-size: 25px;
    }

    .awsiotTopHead h1 {
        font-size: 20px;
    }

    .awsiotTopHead h4,
    .feauturedTopHead h4 {
        font-size: 16px;
    }

    .services_container {
        flex-direction: column;
        height: fit-content;
    }

    .feauturedTopHead {
        width: 100%;
        left: 0;
    }

    .node_red {
        height: 400px;
    }

    .nodered_details,
    .grafana_detatils,
    .thingsboard_details {
        width: 90% !important;
    }

    .nodered_header {
        height: 50%;
    }

    .nodered_details_info {
        height: fit-content;
    }

    .nodered_details_info h6,
    .telegraf_details_info h6 {
        line-height: 2;
        font-size: 16px;
        font-weight: 400 !important;
    }

    .nodered_services_info {
        height: fit-content;
        padding: 0 30px;
    }

    .nodered_image {
        width: 100%;
    }

    .telegraf {
        background: url(../../assets/Services/Feautured/telegraf_bg.webp);
        width: 100%;
        height: 500px;
        background-size: cover;
        background-position: center;
        position: relative;
    }

    .influxDB,
    .grafana {
        height: 400px;
    }

    .influxDB_header-image {
        display: none;
    }

    .grafana_header {
        position: absolute;
        top: 50;
        left: 0;
        width: 100%;
    }

    .feautured_data {
        padding: 10px;
        height: fit-content !important;
    }
}