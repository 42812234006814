@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* nav > a { */
  /* text-decoration: none; */
  /* font-weight: 600; */
  /* font-size: 18px; */
  /* padding: 0 10px; */
  /* color: #00b894; */
  /* font-family: "Montserrat", sans-serif; */
/* } */
nav > a:hover , .content > a:hover{
  /* color: #265892; */
  /* cursor: pointer; */
  /* font-weight: 700; */
  /* font-size: 18px; */
  border-bottom: 2px solid #265892;
}
.navbar_container {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

/* .navbar_container h4 {
  font-size: 18px;
  padding: 0 10px;
  color: #00b894;
  font-family: "Montserrat", sans-serif;
} */

.navbar_container h4:hover {
  color: #265892;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  /* border-bottom: 2px solid #265892; */
}

.white_nav {
  background-color: white;
}

.navbar_container>div {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.digi_logo {
  cursor: pointer;
}

.css-r8u8y9 {
  color: #00b894;
  font-family: "Montserrat", sans-serif;
  background: #f4f7f9;
}

.css-1cl2ir8:hover {
  color: #265892;
  background-color: #00b894;
  color: white;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

nav > a , .content > a , .menu > a {
  color: #00b894;
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
  padding: 0 10px;
}

nav > a , .content > a , .nvaLink > a .menu > a {
  color: #00b894;
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
  padding: 0 10px;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    display: block;
    visibility: visible;
    opacity: 1;
    color: #00b894;
  }

  .navbar_container h4 {
    font-size: 14px;
  }

  .navbar_container h4:hover {
    color: #265892;
    font-size: 14px;
    border-bottom: 2px solid #265892;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 50%;
    height: 30%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    background: #f4f7f9;
    border-radius: 20px;
    transition: 1s;
    padding: 0 10px;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1rem;
    right: 2rem;
    color: #265892;
  }

  nav a ,.content > a  {
    font-size: 14px;
  }


  .dropdown:hover>.menu>a,
.menu>a {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 20px;
  color: #00b894;
  width: 220px;
  padding: 0 10px;
}
}

.dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
}

.dropdown>button {
  border: 0;
  border-radius: 6px;
}
.dropdown>.content {
  color: #00b894;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown>.content::after {
  content: "";
  position: absolute;
  bottom: 6px;
  right: 10%;
  height: 1px;
  transform: scaleX(0);
  transition: 0.3s;
}

.dropdown:hover>.content::after {
  transform: scaleX(1);
}

.dropdown>.content>span:last-child {
  margin-left: auto;
  transition: 0.3s;
}

.dropdown>.menu {
  position: absolute;
  z-index: 1;
  top: 15;
  left: 50%;
  display: grid;
  padding: 20px 0 6px;
  border-radius: 6px;
  translate: -50% 0;
  visibility: hidden;
  opacity: 0;
  scale: 0.85;
  background: #f4f7f9;
  transition: 0.3s;
  margin-top: 30px;
}

.dropdown:hover>.menu {
  visibility: visible;
  opacity: 1;
  scale: 1;
}

.dropdown:hover>.menu>a,
.menu>a {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 20px;
  color: #00b894;
  width: 220px;
  padding: 0 10px;
  font-size: 16px;
  /* border-bottom: 2px solid #00b894; */
}

.dropdown>.menu>a:hover {
  background: #fff;
  color: #265892;
}