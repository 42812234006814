@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");

.backgroundvideo {
  height: 100%;
  width: 100%;
  opacity: 0.9;
}

.typography_heading {
  font-size: 32px;
  color: #00b894;
  padding: 10px 5px 10px 0;
}

.background_nav {
  position: relative;
  min-height: 700px;
  height: 800px;
}

.homepage {
  position: relative;
  height: 70vh;
}

.home_top_innertext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 70px !important;
  width: 70%;
  color: #ffffff;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.home_top_innertext h1 {
  font-size: 70px !important;
  font-weight: 700;
}

.button {
  padding: 30px;
  border-radius: 30px;
}

.link_footer,
.footer_address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
}

.footer_container {
  width: 90% !important;
  position: relative;
}

.footer_icon_social {
  display: flex;
  color: black;
  background-color: antiquewhite;
  border-radius: 6px;
  cursor: pointer;
  padding: 5px;
}

.footer_icon_social:hover {
  background-color: #00b894;
}

.footer_icon_social:hover svg {
  color: white;
}

.footer_icons {
  display: flex;
  color: #6c757d;
  justify-content: center;
  margin-top: 10px;
}

.footer_icon_nav_link {
  color: white;
  display: flex;
  font-size: 16px;
  font-weight: 0 !important;
}

.footer_icon_nav_link:hover {
  color: #00b894;
}

.footer_icon_address {
  display: flex;
}

.footer {
  background: url(../../assets/footer_bg.jpg);
  width: 100%;
  height: 300px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open sans";
  color: white;
  position: relative;
  background-position: center;
}

.footer::before {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  top: 0;
}

.front {
  position: absolute;
  width: 300px;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.aboutUsHead {
  background: url(../../assets/footer_bg.jpg);
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.aboutUsHead::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}

.aboutUsSubhead {
  position: relative;
  text-align: center;
  padding: 65px 0;
  font-size: 55px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0 3px 3px #000;
}

.aboutUsSubhead::after {
  content: "";
  background: #8cc63f;
  width: 35px;
  height: 3px;
  margin: 0 auto;
  display: block;
}

.HomeAbout {
  width: 80% !important;
  margin-top: 50px;
  padding: 0 100px;
  background: #f4f7f9;
}

.homeAboutImages {
  height: 300px;
  width: 300px;
  /* width: 90%; */
}


.homeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f7f9;
}

.projects_data {
  display: flex;
  flex-wrap: wrap;
}

.projectcard {
  width: 100%;
  text-align: center;
  background: #e4e4e6;
  padding: 15px 0;
  border-radius: 10px;
  margin: 0 1px;
}

.homeHead {
  padding: 0;
  height: fit-content;
}

.customers {
  height: fit-content;
  width: 80% !important;
  background: #f4f7f9;
}

.image_wrapper {
  margin: 0 20px;
  background: white;
  border: 1px solid white;
}

.Dashboadrhomepage {
  width: 80% !important;
  padding: 50px 100px;
}

.Dashboadrhomepagediv {
  width: 100%;
}

.Dashboadrhomepagehead {
  color: #00b894;
  padding: 0 10px;
  font-family: "Montserrat", sans-serif;
}

.Dashboadrhomepagecontent {
  padding: 50px;
}

.dashboard_paragraph {
  line-height: 1.8;
}

.Dashboadrhomepagediv1 {
  display: flex;
}

.Dashboadrhomecard {
  height: 200px;
  width: 200px;
  margin: 5px 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 10px;
}

.Dashboadrhomecard h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.Dashboadrhomecard:hover {
  background: linear-gradient(to right, grey, #005242);
  color: #00b894;
}

.analyzehome {
  height: 500px;
  width: 80% !important;
  padding: 0 100px;
  background: #f4f7f9;
}

.homepageSubhead {
  color: #757e8c;
  line-height: 1.5;
  padding-bottom: 10px;
  font-family: "Montserrat";
}

.Dashboadrhomecardlayout {
  margin-top: 20px;
  display: flex;
  flex-direction: row !important;
  flex-direction: column;
  justify-content: space-evenly;
}

.costSaving {
  height: 380px;
  width: 80% !important;
  background: #f4f7f9;
  padding: 0 100px;
}

.costsavinghead {
  padding: 10px;
  height: fit-content;
}

.costsavingcontent {
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.digikloudPlatform {
  width: 80% !important;
  background: #f4f7f9;
  padding: 0 100px;
  height: 500px;
}

.dashboard_platformPage {
  /* width: 1000px; */
  height: 80vh;
  border-radius: 10px;
}

.dashboard_image_slider {
  background: #f4f7f9;
}

.iotprotocols_image {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  padding: 0;
}

/* .dashboard_video {
  display: flex;
  align-items: center;
} */

.iotProtocols {
  height: 500px;
  width: 80% !important;
  background: #f4f7f9;
  padding: 0 100px;
}

.home_content_image,
.analyze_content,
.costsaving_content_image,
.iotProtocols_content_image,
.dashboardhome_content_image {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.analyze_content {
  text-align: justify;
}

.iotProtocols_content_image {
  height: fit-content;
}

.bookdemo_content {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column !important;
  align-items: flex-start;
  padding: 5px;
}

.contact_us_page {
  width: 80% !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_us_page {
  width: 80% !important;
  display: flex;
}

.contact_us_top_container {
  padding: 20px 0px;
  text-align: center;
}

.contact_us_bottom_container {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.test {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}

.contactusFormLabel {
  width: 100%;
  font-size: 16px;
  padding-left: 10px;
}

.contact_us_page_form {
  width: 50%;
}

.bookDemoContainer {
  height: 400px;
  width: 80% !important;
}

.bookdemo_image {
  width: 90%;
  height: 300px;
}

.card {
  width: 150px;
  height: 150px;
  transform-style: preserve-3d;
  transition: all 0.5s ease;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: rotateY(180deg);
}

.front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, grey, #005242);
  border-radius: 10px;
}

.flip_card_container {
  width: 70%;
  margin-left: 20%;
}

.flip_card_bottom {
  margin-top: 10px;
}

.flip_card_container_bottom {
  height: fit-content;
  width: 65%;
  margin-left: 10%;
  margin-top: 10%;
}

.flipCard_subhead {
  text-transform: uppercase;
  text-align: center;
}

.flipcard_back_content {
  color: white;
  font-size: 14px !important;
}

.img_flipCard {
  height: 50px;
  width: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Media Query */

@media only screen and (max-width: 600px) {
  .homepage {
    height: 50vh;
  }

  .home_top_innertext h1 {
    font-size: 25px !important;
  }

  .HomeAbout {
    width: 100% !important;
    padding: 5px 20px;
    margin-top: 0px;
  }

  .customers {
    height: fit-content;
    width: 100% !important;
    background: #f4f7f9;
  }

  .homeAboutImages {
    height: 200px;
    width: 200px;
  }

  .projectcard {
    width: 100%;
    text-align: center;
    background: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
  }

  .projectcard h1 {
    font-size: 26px;
  }

  .backgroundvideo {
    width: 100vw;
    object-fit: cover;
    object-position: center;
  }

  .test {
    width: 100%;
  }

  .background_nav {
    position: relative;
    min-height: 0;
    height: 400px;
  }

  .homeHead {
    padding-left: 10px;
  }

  .analyzehome {
    height: fit-content;
    width: 100% !important;
    padding: 0 20px;
    margin-bottom: 10px;
  }

  .typography_heading {
    font-size: 20px !important;
    color: #00b894;
  }

  .homepageSubhead {
    font-size: 16px !important;
    padding-bottom: 13px;
  }

  .Dashboadrhomepage {
    height: fit-content;
    width: 100% !important;
    padding: 0 20px;
  }

  .Dashboadrhomepagediv1 {
    width: 100% !important;
    height: 700px;
  }

  .Dashboadrhomecardlayout {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .Dashboadrhomecard {
    height: 150px;
    width: 90%;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 10px;
  }

  .Dashboadrhomecard h6 {
    font-size: 12px;
  }

  .costSaving {
    width: 100% !important;
    height: fit-content;
    padding: 0 20px;
  }

  .costsavinghead {
    padding: 0;
  }

  .costsavingcontent {
    padding: 10px;
  }

  .digikloudPlatform {
    height: 700px;
    padding: 0;
  }

  .footer {
    height: fit-content;
  }

  .footer_logo {
    display: none;
  }

  .footer_icon_nav_link,
  .footer_address {
    font-size: 14px;
  }

  .footer_dashboard_links>h6 {
    margin-top: 10px;
  }

  .dashboard_platformPage {
    width: 100%;
    height: 300px;
    border-radius: 10px;
  }

  .footer_container h6 {
    font-size: 16px;
  }

  .iotProtocols {
    height: fit-content;
    width: 100% !important;
    padding: 0 20px;
  }

  .iotprotocols_image {
    height: fit-content;
    border-radius: 20px;
    padding: 0;
  }

  .card {
    width: 100%;
    height: 150px;
    transform-style: preserve-3d;
    transition: all 0.5s ease;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    margin: 10px 0;
  }

  .content_text {
    text-align: left !important;
  }

  .contact_us_page {
    width: 80% !important;
    padding: 0;
  }

  .bookDemoContainer {
    height: fit-content;
    width: 100% !important;
    padding: 0 20px;
  }

  .bookdemo_image {
    display: none;
  }
}

@media only screen and (min-width: 601px) and (max-width: 700px) {
  .HomeAbout {
    width: 100%;
    height: fit-content;
    padding: 5px;
  }

  .backgroundvideo {
    width: 100%;
    top: 0;
    z-index: -1;
    height: 100%;
  }

  .background_nav {
    position: relative;
    min-height: 0;
    height: 400px;
  }

  .homeHead {
    padding-left: 10px;
  }

  .projects_data {
    text-align: center;
    height: 200px;
    flex-direction: row !important;
    margin-bottom: 5px;
  }

  .analyzehome {
    height: fit-content;
    padding: 20px;
  }

  .typography_heading {
    font-size: 25px;
    color: #00b894;
  }

  .homepageSubhead {
    font-size: 14px !important;
    padding-bottom: 13px;
  }

  .Dashboadrhomepage {
    height: fit-content;
    padding: 0;
  }

  .Dashboadrhomepagediv1 {
    width: 100% !important;
    height: 700px;
  }

  .Dashboadrhomecardlayout {
    padding-bottom: 10px !important;
  }

  .Dashboadrhomecard {
    height: 150px;
    width: 130px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 10px;
  }

  .Dashboadrhomecard h6 {
    font-size: 14px;
  }

  .costSaving {
    height: fit-content;
    padding: 0;
  }

  .costsavinghead {
    padding: 0;
  }

  .costsavingcontent {
    padding: 10px;
  }

  .digikloudPlatform {
    height: fit-content;
    padding: 0;
  }

  .iotProtocols {
    padding: 0;
    background-color: #000;
  }

  .footer {
    height: fit-content;
  }
}

@media only screen and (min-width: 701px) and (max-width: 900px) {
  .HomeAbout {
    width: 100%;
    height: fit-content;
    padding: 5px;
  }

  .backgroundvideo {
    width: 100%;
    top: 0;
    z-index: -1;
    height: 100%;
  }

  .background_nav {
    position: relative;
    min-height: 0;
    height: 400px;
  }

  .homeHead {
    padding-left: 10px;
  }

  .projects_data {
    text-align: center;
    height: 200px;
    flex-direction: row !important;
    margin-bottom: 5px;
  }

  .analyzehome {
    height: fit-content;
    padding: 20px;
  }

  .typography_heading {
    font-size: 25px;
    color: #00b894;
  }

  .homepageSubhead {
    font-size: 14px !important;
    padding-bottom: 13px;
  }

  .Dashboadrhomepage {
    height: fit-content;
    padding: 0;
  }

  .Dashboadrhomepagediv1 {
    width: 100% !important;
    height: 700px;
  }

  .Dashboadrhomecardlayout {
    padding-bottom: 10px !important;
  }

  .Dashboadrhomecard {
    height: 150px;
    width: 130px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    padding-left: 10px;
    border-radius: 10px;
  }

  .Dashboadrhomecard h6 {
    font-size: 14px;
  }

  .costSaving {
    height: fit-content;
    padding: 0;
  }

  .costsavinghead {
    padding: 0;
  }

  .costsavingcontent {
    padding: 10px;
  }

  .digikloudPlatform {
    height: fit-content;
    padding: 0;
  }

  .footer {
    height: fit-content;
  }

  .iotProtocols {
    padding: 0;
  }
}